import React, { useEffect, useState } from "react";
import styled from '@emotion/styled';
import Marquee from "react-marquee-slider";
import times from "lodash/times";
import { withSize } from "react-sizeme";
import RobertColvin from "../images/testimonials/RobertColvin.png"
import LeoHernandez from "../images/testimonials/LeoHernandez.png"
import StewartAllen from "../images/testimonials/StewartAllen.png"
import FaustoLendeborg from "../images/testimonials/FaustoLendeborg.png"
import DavidWright from "../images/testimonials/DavidWright.png"
import StevenRemotigue from "../images/testimonials/StevenRemotigue.png"
import AntoniaSalvas from "../images/testimonials/AntoniaSalvas.png"
import JanetGatsby from "../images/testimonials/JanetGatsby.png"
import RudyBrathwaite from "../images/testimonials/RudyBrathwaite.jpg"
import { BLACK_COLOR } from './common/color'
import bp from './common/breakpoints'
import { motion } from 'framer-motion'

const FullWidth = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`

const Height = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.height ? props.height + "px" : "auto")};
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`

const Box = styled.div`
  padding: ${(props) => props.scale * 25}px;
  flex: 0 0 auto;
`

const Review = styled.div`
  width: ${(props) => props.scale * 350}px;
  display: flex;
  padding: ${(props) => props.scale * 25}px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.12);
`

const Avatar = styled.div`
  border-radius: 50%;
  width: ${(props) => props.scale * 58}px;
  height: ${(props) => props.scale * 58}px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: ${(props) => props.scale * 15}px;

  img {
    max-width: 100%;
  }
`

const Content = styled.div`
  p {
    margin: 0;
    color: #444;
    font-family: Helvetica, sans-serif;
    font-size: ${(props) => props.scale * 14}px;
    line-height: ${(props) => props.scale * 20}px;
    font-weight: 100;
    text-align: left;
  }
`

const HeadingWhite = styled(motion.h1)`
  font-size: 2.5rem;
  width: 100%;
  text-align: left;
  line-height: 1.50;
  font-family: 'Manrope' !important;
  color: ${BLACK_COLOR};
  font-weight: 700;
  margin-bottom: 3rem;
  @media (max-width: 812px) {
    font-size: 2.5rem;
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 576px) {
    font-size: 1.8rem;
  }
  ${bp[3]} {
    font-size: 4rem;
    line-height: 1.8;
  }
`

const quotes = [
  {
    author: "Robert Colvin, VP, IT Architecture & Engineering InsureTech Industry ",
    quote: "Having worked with many vendors of all sizes in the past. Alio's IT team is a breath of fresh air. \
    They are able to quickly adapt to our needs and deliver quality and efficient solutions, \
    that avoids repository bloat, sometimes, seen with equivalent vendors. \
    Alio has been pivotal to helping us find the right resource in a timely manner while providing supplemental \
    assistance in other hard to fill areas. It comes as no surprise that I would highly recommend the IT team at Alio.",
    portrait: RobertColvin
  },
  {
    author: "Leo Hernandez, VP of Engineer at NinjaOne",
    quote: "The team at Alio IT has helped augment the internal team in tactical projects by providing skilled resources quickly and on demand. \
    The Alio IT team quickly adapted to our processes and integrated seamlessly to help drive various efforts. \
    We look forward to a continued relationship with Alio IT as an additional resource in our growing technology needs.",
    portrait: LeoHernandez
  },
  {
    author: "Stewart Allen, Associate at Kimley-Horn",
    quote: "Alio played a vital role in our ability to meet schedule and cost commitments for our clients. \
    Their staff are knowledgeable in the technologies and process of delivering software.",
    portrait: StewartAllen
  },
  {
    author: "Fausto Lendeborg, Cybersecurity Expert & Startup Leader",
    quote: "I'm very happy with Alio as part of my technology team, the speed to onboard a new resource is lightning fast. \
    The dedication they have to the project is outstanding. I highly recommend them to anybody that wants to see fast results and good quality.",
    portrait: FaustoLendeborg
  },
  {
    author: "David Wright, CEO Nymbus Media",
    quote: "Working with AlioIT was a game-changer for our audience experience enhancement service. \
    Their seamless integration of Azure backend code and React frontend for mobile elevated our platform to new heights. \
    The team's expertise not only ensured smooth functionality but also enhanced user engagement significantly. \
    Our clients are thrilled with the improved experience, and we couldn't have achieved it without Alio's innovative solutions and dedication. \
    Highly recommend their services!",
    portrait: DavidWright
  },
  {
    author: "Steven Remotigue VP of Engineering at Time4Learning",
    quote: "I am absolutely thrilled with the seamless onboarding process and the technical expertise demonstrated by \
    Alio IT in both front-end and back-end technologies for the EdTech industry. \
    From day one, their team showed remarkable efficiency giving us the confidence to handle any challenges that may arise. \
    Overall, I couldn't be happier with the results and I look forward to continuing our successful partnership as we strive \
    to innovate and transform the EdTech industry together.",
    portrait: StevenRemotigue
  },
  {
    author: "Antonia Salvas - Vice President Operations at Centric Mining Systems",
    quote: "We had a great experience working with the Alio IT team. They worked with us to explore and expand into a new market, \
    the team there was always willing to learn and grow and adapt with us as we explored this new market. \
    They worked closely with us to learn our technology which is very niche and could be difficult to learn in some cases but they were \
    easily able to work with our team to learn the product and the technology that we have in order to bring this to a new market.",
    portrait: AntoniaSalvas
  },
  {
    author: "Janet Gatsby, Janet Gatsby Consulting LLC",
    quote: "As an Agile Coach, I know the struggle: integrating an outsourced team seamlessly with your own. Most agencies promise it, \
    but few deliver. ALIO IT is different. They excel at creating a truly unified team environment, which is crucial for successful Agile practices.",
    portrait: JanetGatsby
  },
  {
    author: "Rudy Brathwaite, Senior Product Owner at the University of Saint Augustine for Health Sciences",
    quote: "Working with Alio IT over the past five years has been an exceptional experience. \
    Their expertise in Salesforce solutions has transformed our operations, making us more efficient and effective. \
    But what truly sets them apart is their commitment to our success – they're not just a contracted team; they're family. \
    Their professionalism, reliability, and collaborative approach make them an invaluable partner. Highly recommended!",
    portrait: RudyBrathwaite
  }
]

const Testimonials = ({ size, onStartPerformance, onEndPerformance }) => {
  let uid = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 10) {
    uid += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  const [key, setKey] = useState(uid);

  useEffect(() => {
    setKey(uid);
  }, [size, setKey]);

  let scale = 1;
  let velocity = 5;
  if (size && size.width > 500) {
    velocity = 10;
  }

  if (size && size.width > 600) {
    velocity = 15;
  }

  if (size && size.width > 800) {
    velocity = 20;
  }

  if (size && size.width > 1100) {
    velocity = 25;
  }

  if (size && size.width > 1400) {
    velocity = 30;
  }

  return (
    <div>
      <HeadingWhite className='typewriter4'>Testimonials</HeadingWhite>
      <FullWidth>
        <Height height={400}>
          <Marquee
            key={key}
            velocity={velocity}
            minScale={0.7}
            onInit={onStartPerformance}
            onFinish={onEndPerformance}
          >
            {quotes.map((quote, index) => (
              <Box key={`marquee-example-review-${index}`} scale={scale}>
                <Review scale={scale}>
                  <Avatar scale={scale}>
                    <img src={quote.portrait} alt={quote.author} />
                  </Avatar>
                  <Content scale={scale}>
                    <p style={{ fontWeight: "bold" }}>{quote.author}</p>
                    <p style={{ textAlign: "justify" }}>{quote.quote}</p>
                  </Content>
                </Review>
              </Box>
            ))}
          </Marquee>
        </Height>
      </FullWidth>
    </div >
  );
};
//export default Testimonials;
export default React.memo(withSize()(Testimonials));