import React, { useState } from 'react'
import styled from '@emotion/styled'
import Lottie from 'react-lottie'
import animationData from './lotties/catAvatar'
import { BLACK_COLOR } from './common/color'
import bp, { MOBILE_DEVICE } from './common/breakpoints'
import { motion } from 'framer-motion'
import { MainButton } from './common/button';
import pdfWhite from '../images/assets/PDFWhite.png'
import { WhitePaperText } from '../components/common/typography'
import { useMediaQuery } from '@react-hook/media-query'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const HeadingWhite = styled(motion.h1)`
  font-size: 2.5rem;
  width: 100%;
  text-align: left;
  line-height: 1.50;
  font-family: 'Manrope' !important;
  color: ${BLACK_COLOR};
  font-weight: 700;
  margin-bottom: 3rem;
  @media (max-width: 812px) {
    font-size: 2.5rem;
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 576px) {
    font-size: 1.8rem;
  }
  ${bp[3]} {
    font-size: 4rem;
    line-height: 1.8;
  }
`
const ContentContainer = styled.div`
display: grid;

${bp[3]} {
  grid-template-columns: 1fr 2fr; 
  }
`
const LottieContainer = styled.div`
display: flex;
padding-bottom: 10px;
`
const ButtonContainer = styled.div`
display: grid;
grid-template-rows: 2fr;
`

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-225px'
}

const buttonStyleMovil = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const lottieStyle = {
  overflow: 'hidden',
  borderRadius: '8px'
}
const ImgContainer = styled.div`
display:none;
align-items: center;
justify-content: center;

${bp[3]} {
  display:flex;
  }
`

const NftInfo = () => {
  const matchMobile = useMediaQuery(`(min-width: ${MOBILE_DEVICE}px)`)
  return (
    <div>
      <HeadingWhite className='typewriter4'>Check out our <span style={{ color: '#EF761F' }}> White Papers </span> </HeadingWhite>
      <ContentContainer>
        <LottieContainer>
          <ImgContainer>
            <img src={pdfWhite} alt="" style={{ maxWidth: "500px" }} />
          </ImgContainer>
        </LottieContainer>
        <ButtonContainer>
          <WhitePaperText>
            Our industry experts have some interesting white papers for you to read. Feel free to download them to start up a conversation with us.
          </WhitePaperText>
          <MainButton
            to='/white-paper'
            icon={'trending-up'}
            style={matchMobile ? buttonStyle : buttonStyleMovil}>
            Find out more
          </MainButton>
        </ButtonContainer>
      </ContentContainer>
    </div >
  );
}
export default NftInfo