import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import AppLayout from '../components/layouts/appLayout';
import SEO from '../components/seo';
import ContactForm from '../components/contact-form';
import AlioVision from '../components/alioVision';
import Hero from '../components/hero';
import ServicesLanding from '../components/servicesLanding';
import bp from '../components/common/breakpoints';
import NftInfo from '../components/nftInfo';
import Testimonials from '../components/testimonials';
import { Phrase } from '../components/common/typography';
import { PRIMARY_COLOR } from '../components/common/color';
import CarouselImages from '../pages/carouselimages';
import { Walktour, CardinalOrientation } from 'walktour';
import { CustomTooltip } from '../components/tourCustomTooltip';
import SearchComponent from '../components/SearchComponent';
import Cookies from 'universal-cookie';

const Content = styled.div`
  z-index: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10vh;
  &:focus {
    outline: none;
  }
`;

const SectionContainer = styled.div`
  height: 100%;
  width: 100%;
  ${bp[3]} {
    // height: calc(100vh - 116px);
  }
`;

const HomePage = () => {
  const cookies = new Cookies(null, { path: '/' });
  const [tourOpen, setTourOpen] = useState(false);

  useEffect(() => {
    let startTour = true;

    // Check if the user is on a mobile device
    const isMobile = typeof window !== 'undefined' && window.matchMedia("(max-width: 768px)").matches;

    if (cookies.get('Tour1') === 'Yes' || isMobile) {
      startTour = false;
    }

    cookies.set('Tour1', 'Yes');
    setTourOpen(startTour);
  }, []);

  const closeTour = (logic) => {
    setTourOpen(false);
    logic.close();
  };

  const customNext = (logic, target) => {
    if (target === true) {
      closeTour(logic);
    } else {
      logic.next();
    }
  };

  const tourSteps = [
    {
      selector: '#whoAreYou',
      title: '',
      description: 'Please share with us about who you are by clicking on the buttons below',
      disableNext: false,
      disableClose: true,
      customTooltipRenderer: (logic) => <CustomTooltip {...logic} {...logic.stepContent} />,
      movingTarget: true,
    },
    {
      selector: '#wizardsContainer',
      title: '',
      description: 'Click any to continue',
      disableNext: true,
      disableClose: true,
      nextOnTargetClick: true,
      customTooltipRenderer: (logic) => <CustomTooltip {...logic} {...logic.stepContent} />,
      movingTarget: true,
    },
    {
      selector: '#servicesContainer',
      title: '',
      description: 'Hover to any card and click to see more details',
      disableNext: true,
      disableClose: true,
      nextOnTargetClick: true,
      customNextFunc: (logic, fromTarget) => customNext(logic, fromTarget),
      customTooltipRenderer: (logic) => <CustomTooltip {...logic} {...logic.stepContent} />,
      movingTarget: true,
    },
  ];

  return (
    <>
      <AppLayout>
        <SEO title="Software Development Agency" canonical="Home" />
        <CarouselImages />
        <Content>
          <SectionContainer>
            <Phrase>
              Your Remote Team But
              <span style={{ color: PRIMARY_COLOR }}> Closer</span>{' '}
            </Phrase>
            <Hero />
          </SectionContainer>
          <SectionContainer>
            <ServicesLanding />
          </SectionContainer>
          <SectionContainer>
            <Testimonials />
          </SectionContainer>
          <SectionContainer>
            <NftInfo />
          </SectionContainer>
          <SectionContainer>
            <AlioVision />
          </SectionContainer>
          <SectionContainer>
            <ContactForm />
          </SectionContainer>
        </Content>
      </AppLayout >
      <Walktour
        steps={tourSteps}
        isOpen={tourOpen}
        customCloseFunc={(logic) => closeTour(logic)}
        disableCloseOnClick
      />
    </>
  );
};

export default HomePage;
